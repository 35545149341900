<script setup>
import emitter from '@/plugins/eventBus.js'
import {onBeforeUnmount, onMounted, computed, ref} from "vue";
import {scrollToContainer} from "@/plugins/helpers.js";
import ResponsivePicture from "@/Components/ResponsivePicture.vue";

const {componentId, content, settings} = defineProps({
    componentId: String,
    content: Object,
    settings: Object,
});

const bgImage = computed(() => content?.image)

onMounted(() => emitter.$on(componentId, () => scrollToContainer(componentId, 100)));
onBeforeUnmount(() => emitter.$off(componentId));
</script>

<template>
    <div class="relative">
        <component
            :id="componentId"
            :is="settings.tag || 'h3'"
            class="
                absolute pt-16 sm:pt-32 md:pt-44 lg:pt-64 pl-1 font-normal font-philosopher
                text-4xl md:text-5xl lg:text-6xl xl:text-7xl
            "
            :style="{ color: settings.text_color}"
            v-html="content.text"
        >
        </component>

        <ResponsivePicture
            :data="bgImage"
            img-classes="d-block w-full mx-auto"
        />
    </div>
</template>
