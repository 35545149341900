<script setup>
const value = defineModel({type: Boolean});
const props = defineProps({
    type: {
        type: String,
        default: 'checkbox'
    },
    name: String,
    label: String
});
</script>

<template>
    <label class="inline-flex items-center cursor-pointer">
        <input
            v-model="value"
            :type="props.type"
            :name="props.name"
            class="sr-only peer"
            :value="props.type === 'radio' ? props.label : value"
        >
        <div
            class="relative min-w-[30px] h-5 bg-gray-dark rounded-full peer peer-checked:after:translate-x-[9px] rtl:peer-checked:after:-translate-x-[9px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2.5px] after:start-[3px] after:bg-gray after:rounded-full after:h-[15px] after:w-[15px] after:transition-all peer-checked:bg-green"
        ></div>
        <span class="ms-6 text-desc-13 font-medium text-black whitespace-nowrap">{{ props.label }}</span>
    </label>
</template>
