<script setup>
const props = defineProps({
    index: Number,
    colsProportions: String
})

const getColSpan = () => {
    const index = props.index + 1;
    if (index === 1) {
        switch (props.colsProportions) {
            case 'cols-2/3-1/3':
            case'cols-2/4-1/4-1/4':
                return 2;

            case 'cols-3/6-2/6':
                return 3
            case 'cols-4/6-2/6':
                return 4
            default:
                return 1;
        }
    }
    if (index === 2) {
        switch (props.colsProportions) {
            case 'cols-1/3-2/3':
            case 'cols-1/4-2/4-1/4':
            case 'cols-3/6-2/6':
            case 'cols-4/6-2/6':
                return 2;
            default:
                return 1;
        }
    }
    if (index === 3) {
        switch (props.colsProportions) {
            case 'cols-1/3-2/3':
            case 'cols-1/4-1/4-2/4':
                return 2;
            default:
                return 1;
        }
    }
}
</script>

<template>
    <div
        class="col-span-1"
        :class='{
"lg:col-span-1": getColSpan() === 1,
"lg:col-span-2": getColSpan() === 2,
"lg:col-span-3": getColSpan() === 3,
"col-span-2 lg:col-span-4": getColSpan() === 4
                }'
    >
        <slot/>
    </div>
</template>

<style scoped>

</style>