export default {
    fullname: 'ПІП',
    name: "Ім'я",
    phone: 'Телефон',
    email: 'Email',
    topic: 'Оберіть тему',
    addressDelivery: 'адресна доставка Новою Поштою',
    pickup: 'самовивіз з Нової Пошти',
    courierDelivery: 'кур’єрна доставка',
    warehousePickup: 'самовивіз  з нашого складу',
    bankAccount: 'на розрахунковий рахунок',
    card: 'переказ на карту',
    necessarily: 'обов’язково',
    receiver: 'Одержувач',
    delivery: 'Доставка',
    deliveryType: 'Спосіб доставки',
    carrierTariffs: 'За тарифами перевізника',
    negotiable: 'Договірна',
    free: 'Безкоштовно',
    paymentMethod: 'Спосіб оплати',
    edit: 'Редагувати',
    earlyTotal: 'Попередній підсумок',
    confirmOrder: 'Підтвердити замовлення',
    receiptWithPrepayment: 'при отриманні з передплатою',
    workingText: 'Працюємо',
    workingHoursText: 'пн-пт (9:00-18:00)<br>сб-нд (відпочиваємо)',
    titleHome: 'Головна',
    titleAbout: 'Про нас',
    titleCart: 'Кошик',
    titleCheckout: 'Оформлення замовлення',
    titleContacts: 'Контакти',
    titlePartnership: 'Партнерство',
    homeSubtitle: 'F’DOOR поєднує мінімалістичний <br>дизайн з відмінною майстерністю <br>створюючи двері та перегородки<br>в сучасному стилі.',
    handle: 'Дверна ручка',
    handleType: 'Тип дверної ручки',
    handleCoverWC: 'Накладка під WC',
    cylinderCover: 'Накладка під циліндр',
    choseOtherHandle: 'Обрати іншу ручку',
    doorDimensions: 'Розміри полотна',
    connectWithManager: 'Зв’язатися з менеджером',
    addToCart: 'Додати до кошика',
    dimensionHW: 'Розмір В*Ш',
    profileColor: 'Колір профілю',
    opening: 'Відкривання',
    complexion: 'Комплектація',
    brand: 'Бренд',
    covering: 'Покриття',
    pcs: 'шт',
    perSet: 'за комплект',
    delete: 'Видалити',
    earlySetPrice: 'Попередня ціна комплекту',
    contact: 'Контакт',
    sendRequest: 'Надіслати запит',
    addedToYourCard: 'Додано у ваш кошик',
    seeCard: 'Переглянути кошик',
    goToCheckout: 'Перейти до оформлення',
    seeAllDoorHandlesCollection: 'Переглянути всю колекцію дверних ручок',
    yourOrder: 'Ваше запит',
    yourOrderSendtoManagerText: 'передано в роботу менеджеру. З вами зв’яжуться найближчим часом для уточнення інформації.',
    thank: 'Дякуємо',
    catalog: 'Каталог',
    information: 'Інформація',
    userAgreement: 'Угода користувача',
    privacyPolicy: 'Політика конфіденційності',
    doorsDefault: 'Двері прихованого монтажу',
    continueShopping: 'Продовжити покупки',
    goToShopping: 'Перейти до покупок',
    yourCartIsEmpty: 'Ваш кошик порожній',
    connectWithUs: 'Зв’яжіться з нами',
    orderCallback: 'Замовити зворотній дзвінок',
    address: 'Адреса',
    yourMessages: 'Ваші повідомлення',
    contactsPageContactBlockText: 'Готові реалізувати свої унікальні задуми? Наші менеджери допоможуть вам зробити найкращий вибір враховуючи ваші ідеї, функціональні потреби та бюджет.',
    workWithArchitectorsDesigners: 'Співпрацюємо з архітекторами <br/>та дизайнерами інтер’єрів',
    unstandardDimentions: 'Якщо вам потрібні <span class="text-red font-semibold">нестандартні розміри</span>, зв’яжіться з менеджером для прорахунку',
    art: 'арт.',
    advantagesBlockKey1: 'Реалізація<br />“під ключ”',
    advantagesBlockKey2: 'Професійна<br />підтримка',
    advantagesBlockKey3: 'Бібліотека<br />зразків',
    advantagesBlockKey4: 'Персональні<br />умови',
    'doors-product' : "Двері",
    'doors-custom-product': "Двері на замовлення",
    'accessories': "Аксесуари"
}
