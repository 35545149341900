export default {
    fullname: 'ФИО',
    name: "Имя",
    phone: 'Телефон',
    email: 'Email',
    topic: 'Выберите тему',
    addressDelivery: 'адресная доставка Новой почтой',
    pickup: 'самовывоз из Новой Почты',
    courierDelivery: 'курьерная доставка',
    warehousePickup: 'самовывоз из нашего склада',
    bankAccount: 'на расчетный счет',
    card: 'перевод на карту',
    necessarily: 'обязательно',
    receiver: 'Получатель',
    delivery: 'Доставка',
    deliveryType: 'Способ доставки',
    carrierTariffs: 'По тарифам перевозчика',
    negotiable: 'Договорная',
    free: 'Бесплатно',
    paymentMethod: 'Способ оплаты',
    edit: 'Редактировать',
    earlyTotal: 'Предварительная сумма',
    confirmOrder: 'Подтвердить заказ',
    receiptWithPrepayment: 'при получении с подпиской',
    workingText: 'Работаем',
    workingHoursText: 'пн-пт (9:00-18:00)<br>сб-вс (отдыхаем)',
    titleHome: 'Главная',
    titleAbout: 'О нас',
    titleCart: 'Корзина',
    titleCheckout: 'Оформление заказа',
    titleContacts: 'Контакты',
    titlePartnership: 'Партнерство',
    homeSubtitle: 'F’DOOR сочетает минималистичный <br>дизайн с отличным мастерством <br>создавая двери и перегородки<br>в современном стиле.',
    handle: 'Дверная ручка',
    handleType: 'Тип дверной ручки',
    handleCoverWC: 'Накладка под WC',
    cylinderCover: 'Накладка под цилиндр',
    choseOtherHandle: 'Выбрать другую ручку',
    doorDimensions: 'Размеры полотна',
    connectWithManager: 'Связаться с менеджером',
    addToCart: 'Добавить в корзину',
    dimensionHW: 'Размер В*Ш',
    profileColor: 'Цвет профиля',
    opening: 'Открывание',
    complexion: 'Комплектация',
    brand: 'Бренд',
    covering: 'Покрытие',
    pcs: 'шт',
    perSet: 'за комплект',
    delete: 'Удалить',
    earlySetPrice: 'Предварительная цена комплекта',
    contact: 'Контакт',
    sendRequest: 'Отправить запрос',
    addedToYourCard: 'Добавлено в вашу корзину',
    seeCard: 'Посмотреть корзину',
    goToCheckout: 'Перейти к оформлению',
    seeAllDoorHandlesCollection: 'Посмотреть всю коллекцию дверных ручек',
    yourOrder: 'Ваш запрос',
    yourOrderSendtoManagerText: 'передан в работу менеджеру. С вами свяжутся в ближайшее время для уточнения информации.',
    thank: 'Благодарим',
    catalog: 'Каталог',
    information: 'Информация',
    userAgreement: 'Соглашение пользователя',
    privacyPolicy: 'Политика конфиденциальности',
    doorsDefault: 'Двери скрытого монтажа',
    continueShopping: 'Продолжить покупки',
    goToShopping: 'Перейти к покупкам',
    yourCartIsEmpty: 'Ваша корзина пуста',
    connectWithUs: 'Свяжитесь с нами',
    orderCallback: 'Заказать обратный звонок',
    address: 'Адрес',
    yourMessages: 'Ваши сообщения',
    contactsPageContactBlockText: 'Готовы реализовать свои уникальные задумки? Наши менеджеры помогут вам сделать лучший выбор учитывая ваши идеи, функциональные потребности и бюджет.',
    workWithArchitectorsDesigners: 'Сотрудничаем с архитекторами <br/>и дизайнерами интерьеров',
    unstandardDimentions: 'Если вам необходимы <span class="text-red font-semibold">нестандартные размеры</span>, свяжитесь с менеджером для просчета',
    art: 'арт.',
    advantagesBlockKey1: 'Реализация<br />“под ключ”',
    advantagesBlockKey2: 'Профессиональная<br />поддержка',
    advantagesBlockKey3: 'Библиотека<br />образцов',
    advantagesBlockKey4: 'Персональные<br />условия',
    'doors-product' : "Двери",
    'doors-custom-product': "Двери на заказ",
    'accessories': "Аксессуары"
}
