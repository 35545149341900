<script setup>
import {ref} from "vue";
import AppLayout from "@/Layouts/AppLayout.vue";
import Breadcrumb from '@/Components/Breadcrumb.vue';
import PartitionCharacteristic from '@/Components/PartitionCharacteristic.vue';
import PartitionItem from "@/Components/PartitionItem.vue";
import Slider from '@/Components/Slider.vue';
import {useI18n} from 'vue-i18n';
import NovaComponentsLoader from "@/Components/NovaComponentsLoader.vue";
import {usePage} from "@inertiajs/vue3";

const {t} = useI18n();
const breadcrumbs = ref([
    {
        title: t('titleHome'),
        disabled: false,
        href: '/',
    },
    {
        title: usePage().props.category?.title,
        disabled: false,
        href: usePage().props.category?.href,
    },
    {
        title: usePage().props.h1,
        disabled: true,
        href: usePage().props.href,
    },
]);

</script>

<template>
    <AppLayout>
        <section class="bg-neutral-100 py-10 ">
            <Breadcrumb
:breadcrumbs="breadcrumbs"
class="container"
></Breadcrumb>
        </section>

        <section class="bg-neutral-100  pb-10 sm:pb-16 lg:pb-24">
            <div class="container">
                <PartitionCharacteristic/>
            </div>
        </section>

        <template v-if="$page.props.similarPartitions.length">
            <section class="bg-neutral-100 pb-10 sm:pb-16 lg:pb-24">
                <Slider
class="container"
:items="$page.props.similarPartitions"
gap-x="80px"
:gap-y="true"
>
                    <template #title>{{ t("Вас можуть зацікавити інші дизайни") }}</template>
                    <template #slide="partitions">
                        <PartitionItem v-bind="partitions.item"/>
                    </template>
                </Slider>
            </section>
        </template>

        <NovaComponentsLoader/>
    </AppLayout>
</template>