<script setup>
import { computed, ref } from 'vue';
import NewModal from '@/Components/NewModal.vue';
import Slider from '@/Components/Slider.vue';

const props = defineProps({
    id: Number,
    product_images: Array,
    title: String,
    product_price: [Number, String],
    product_specification: [Array, Object],
    vendor_code: String,
})

const isModalOpen = ref(false);
const activeModalIndex = ref(0);
const setActiveModalImage = index => activeModalIndex.value = index;
const formettedPrice = computed(() => new Intl.NumberFormat('uk-UA').format(props.product_price));
</script>

<template>
    <div
        v-if="props?.id"
        class="mb-12 lg:mb-6"
    >
        <div class="handle-images grid grid-cols-4 lg:flex gap-2 lg:h-2/5 xl:h-auto">
            <template v-for="(image, index) in props.product_images">
                <button
                    type="button"
                    class="bg-white lg:max-h-[180px] lg:max-w-[180px]"
                    @click="setActiveModalImage(index); isModalOpen = true"
                >
                    <img
                        class="cursor-pointer hover:shadow-silver lg:h-full"
                        :src="image.medium"
                        :alt="props.title"
                    >
                </button>
            </template>

            <NewModal
                :is-open="isModalOpen"
                classes="sm:w-[500px]"
                @close="isModalOpen = false"
            >
                <Slider
                    v-if="isModalOpen"
                    :items="props.product_images"
                    :has-title="false"
                    :start-index="activeModalIndex"
                    loop
                >
                    <template #slide="option">
                        <img
                            :src="option.item.medium"
                            :alt="props.title"
                            class="object-cover w-full h-full"
                        />
                    </template>
                </Slider>
            </NewModal>
        </div>
        <div class="handle-description text-black-dark text-desc-13 leading-4 font-normal w-full sm:w-1/2 lg:w-3/5 mt-5 pr-5 space-y-3">
            <p class="w-full flex justify-between font-medium">{{ props.title }}
                <span class="text-black font-semibold whitespace-nowrap">{{ formettedPrice }} ₴</span>
            </p>
            <p>{{ $t('art') + ' ' + props.vendor_code }}</p>
            <p
                v-for="specification in props.product_specification"
                class="w-full flex justify-between"
            >
                {{ specification.name }} <span class="text-black">{{ specification?.value }}</span>
            </p>
        </div>
    </div>
</template>
