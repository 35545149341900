<script setup>
import {useStore} from "vuex";
import {ref} from "vue";
import {useI18n} from "vue-i18n";

import AppLayout from "@/Layouts/AppLayout.vue";
import NavigationImage from '@/Components/NavigationImage.vue';
import NovaComponentsLoader from "@/Components/NovaComponentsLoader.vue";
import ResponsivePicture from "@/Components/ResponsivePicture.vue";

const store = useStore();
const locale = ref(useI18n().locale);
</script>

<template>
    <AppLayout>
        <section class="relative font-philosopher">
            <div class="container absolute left-0 top-0  flex flex-col pl-11 py-16 sm:pt-30 sm:pb-52 lg:pt-[15rem] lg:pb-[35rem] md:relative z-10 h-auto lg:h-[48rem] xl:h-[54rem]">
                <h1 class="font-philosopher text-black text-5xl lg:text-7xl pt-3 md:pt-0 mr-[70vw] sm:mr-0">{{ $page.props.h1 }}</h1>
            </div>
            <ResponsivePicture
                class="md:absolute z-0 left-0 top-0 w-full h-full"
                :data="$page.props.coverImage"
                img-classes="object-cover w-full h-full "
            />
        </section>

        <section class="bg-neutral-100 font-philosopher">
            <h2 class="text-green text-2xl md:text-4xl font-normal text-center px-5 py-10 sm:py-16 lg:py-24">
                <span v-html="$t('homeSubtitle')"></span>
            </h2>
        </section>

        <section class="bg-neutral-100 pb-10 sm:pb-16 lg:pb-24">
            <div class="container grid grid-cols-1 md:grid-cols-2 gap-6 items-center font-philosopher">
                <NavigationImage
                    :href="store.state.links?.['doors-product']?.[locale]"
                    :img-src="store.state.links?.['doors-product']?.image"
                    :caption="store.state.links?.['doors-product']?.title?.[locale]"
                />
                <NavigationImage
                    :href="store.state.links?.['doors-custom-product']?.[locale]"
                    :img-src="store.state.links?.['doors-custom-product']?.image"
                    :caption="store.state.links?.['doors-custom-product']?.title?.[locale]"
                />
            </div>
        </section>

        <NovaComponentsLoader/>
    </AppLayout>
</template>
