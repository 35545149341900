<script setup>
import { ref } from 'vue';

import NewModal from '@/Components/NewModal.vue';
import Slider from '@/Components/Slider.vue';

const props = defineProps({
    options: Array,
    title: String,
    price: [Number, String],
    article: String,
    brand: String,
    product_specification: [Array, Object],
})
const activeModalIndex = ref(0);
const setActiveModalImage = index => activeModalIndex.value = index;

const isModalOpen = ref(false);
</script>

<template>
    <div class="handle-images grid grid-cols-4 gap-2">
        <div
            v-for="(handleOption, index) in options"
            class="col-span-1"
        >
            <button
                type="button"
                class="bg-white"
                @click="setActiveModalImage(index); isModalOpen = true"
            >
                <img
                    class="cursor-pointer hover:shadow-silver"
                    :src="handleOption.medium"
                    :alt="props.title"
                >
            </button>
        </div>

        <NewModal
            :is-open="isModalOpen"
            classes="sm:w-[500px]"
            @close="isModalOpen = false"
        >
            <Slider
                v-if="isModalOpen"
                :items="options"
                :has-title="false"
                :start-index="activeModalIndex"
                loop
            >
                <template #slide="option">
                    <img
                        :src="option.item.medium"
                        alt="Дверна ручка"
                        class="object-cover w-full h-full"
                    />
                </template>
            </Slider>
        </NewModal>
    </div>
    <div class="handle-description text-black-dark text-desc-13 leading-4 font-normal w-full sm:w-1/2 my-5 pr-2 space-y-3">
        <p class="w-full flex justify-between font-medium">
            <span>{{ props.title }}</span>
            <span class="pl-2 text-black font-semibold whitespace-nowrap">{{ props.price }} ₴</span>
        </p>
        <p>{{ $t('art') + ' ' + props.article }}</p>
        <p
            v-for="specification in props.product_specification"
            class="w-full flex justify-between"
        >
            {{ specification.name }} <span class="text-black">{{ specification?.value }}</span>
        </p>
    </div>
</template>
