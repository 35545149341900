<script setup>
import AppLayout from "@/Layouts/AppLayout.vue";
import NovaComponentsLoader from "@/Components/NovaComponentsLoader.vue";

import Breadcrumb from '@/Components/Breadcrumb.vue';
import ButtonsGroup from "@/Components/ButtonsGroup.vue";
import ImageContent from '@/Components/ImageContent.vue';
import GlassTypes from '@/Components/GlassTypes.vue';
import DividedContent from "@/Components/DividedContent.vue";
import PartitionModels from "@/Components/PartitionModels.vue";
import {useI18n} from 'vue-i18n';
import PartitionModel1SRC from '@images/partition-model-1.png';
import PartitionModel2SRC from '@images/partition-model-2.png';
import PartitionModel3SRC from '@images/partition-model-3.png';
import PartitionModel4SRC from '@images/partition-model-4.png';
import PartitionModel5SRC from '@images/partition-model-5.png';
import PartitionModel6SRC from '@images/partition-model-6.png';
import PartitionModel7SRC from '@images/partition-model-7.png';
import PartitionModel8SRC from '@images/partition-model-8.png';
import PartitionModel9SRC from '@images/partition-model-9.png';
import PartitionModel10SRC from '@images/partition-model-10.png';
import PartitionModel11SRC from '@images/partition-model-11.png';
import PartitionModel12SRC from '@images/partition-model-12.png';

import SteelWireGlassURL from '@images/steel-wire-glass.png';
import CathedralGlassURL from '@images/cathedral-glass.png';
import FluteGlassURL from '@images/flute-glass.png';
import VisionGlassURL from '@images/vision-glass.png';
import ScreenGlassURL from '@images/screen-glass.png';
import ListralGlassURL from '@images/listral-glass.png';
import MilkGlassURL from '@images/milk-glass.png';
import WhiteGlassURL from '@images/white-glass.png';
import TransparentGlassURL from '@images/transparent-glass.png';
import FumeBrownGlassURL from '@images/fume-brown-glass.png';
import FumeGrayGlassURL from '@images/fume-gray-glass.png';
import BlackGlassURL from '@images/black-glass.png';

const {t} = useI18n();
const breadcrumbs = [
    {
        title: t('titleHome'),
        disabled: false,
        href: '/',
    },
    {
        title: 'Cкляні міжкімнатні перегородки',
        disabled: false,
        href: '/',
    },
];

const aboutText = t(`
    можливість створення зон приватності шляхом пропускання природного світла та майстерного дозування простору;<br><br>
    усвідомлення використання екологічно безпечного матеріалу, який нескінченно переробляється;<br><br>
    легкість і простота монтажу в порівнянні з кладкою;<br><br>
    гнучкість набору конструкції.
`);

const partitions = [
    {href: '#', src: PartitionModel1SRC, title: 'Astori Leggeri'},{href: '#', src: PartitionModel2SRC, title: 'Astori Leggeri'},{href: '#', src: PartitionModel3SRC, title: 'Astori Leggeri'},
    {href: '#', src: PartitionModel4SRC, title: 'Astori Leggeri'},{href: '#', src: PartitionModel5SRC, title: 'Astori Leggeri'},{href: '#', src: PartitionModel6SRC, title: 'Astori Leggeri'},
    {href: '#', src: PartitionModel7SRC, title: 'Astori Leggeri'},{href: '#', src: PartitionModel8SRC, title: 'Astori Leggeri'},{href: '#', src: PartitionModel9SRC, title: 'Astori Leggeri'},
    {href: '#', src: PartitionModel10SRC, title: 'Astori Leggeri'},{href: '#', src: PartitionModel11SRC, title: 'Astori Leggeri'},{href: '#', src: PartitionModel12SRC, title: 'Astori Leggeri'},
]

const glassTypes = [
    {src: SteelWireGlassURL, title: 'скло зі сталевим дротом'}, {src: CathedralGlassURL, title: 'собор'}, {src: FluteGlassURL, title: 'флейтове скло'}, {src: VisionGlassURL, title: 'візіо'},
    {src: ScreenGlassURL, title: 'екран'}, {src: ListralGlassURL, title: 'лістраль'}, {src: MilkGlassURL, title: 'флейтове скло'}, {src: WhiteGlassURL, title: 'візіо'},
    {src: TransparentGlassURL, title: 'скло зі сталевим дротом'}, {src: FumeBrownGlassURL, title: 'собор'}, {src: FumeGrayGlassURL, title: 'флейтове скло'}, {src: BlackGlassURL, title: 'візіо'},
]
</script>

<template>
    <AppLayout>
        <section class="bg-neutral-100 py-10 ">
            <Breadcrumb
                :breadcrumbs="breadcrumbs"
                class="container"
            ></Breadcrumb>
        </section>

        <NovaComponentsLoader avoid-padding-top/>
    </AppLayout>
</template>
