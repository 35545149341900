<script setup>
import AppLayout from "@/Layouts/AppLayout.vue";
import Breadcrumb from "@/Components/Breadcrumb.vue";
import NovaComponentsLoader from "@/Components/NovaComponentsLoader.vue";
import {useI18n} from "vue-i18n";

const {t} = useI18n();

const breadcrumbs = [
    {
        title: t('titleHome'),
        disabled: false,
        href: '/',
    },
    {
        title: t('titleAbout'),
        disabled: true,
        href: '/',
    },
];
</script>

<template>
    <AppLayout>
        <section class="bg-neutral-100 py-10 ">
            <Breadcrumb
                :breadcrumbs="breadcrumbs"
                class="container"
            ></Breadcrumb>
        </section>

        <NovaComponentsLoader avoidPaddingTop />
    </AppLayout>
</template>
